<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <h4>Регистър мостри</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="headers.search"
            prepend-inner-icon="search"
            clearable
            :label="$t('A.SEARCH')"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <div class="form-group">
            <label for="">
              <span> Начална дата:</span>
            </label>
            <div class="field-wrap">
              <datetime
                zone="local"
                value-zone="local"
                type="date"
                v-model="headers.start_date"
                input-class="form-control"
              ></datetime>
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="form-group">
            <label for="">
              <span> Крайна дата:</span>
            </label>
            <div class="field-wrap">
              <datetime
                zone="local"
                value-zone="local"
                type="date"
                v-model="headers.end_date"
                input-class="form-control"
              ></datetime>
            </div>
          </div>
        </v-col>
        <v-col
          cols="3"
          class="d-flex justify-content-center align-items-center"
        >
          <div v-on:click="downloadData()" class="btn btn-primary">
            Свали експорт
          </div>
        </v-col>
      </v-row>

      <v-row class="mb-6 t-header">
        <template v-for="column of headers.columns">
          <v-col
            :key="column.name"
            :cols="column.cols"
            v-if="column.hide != true"
          >
            <span @click="changeOrder(column.name)">
              {{ $t("LABELS." + column.name) }}
              <span v-if="column.name == headers.order">{{
                orderIcon
              }}</span></span
            >
          </v-col>
        </template>
        <v-col cols="2"> Операции </v-col>
      </v-row>

      <div v-show="!isLoadingSampleRegister">
        <v-row class="mb-6 t-row" v-for="item of items" :key="item.id">
          <template v-for="col of headers.columns">
            <v-col
              class="t-col"
              :key="col.name"
              :cols="col.cols"
              v-if="col.hide != true"
            >
              {{ item[col.name] }}
            </v-col>
          </template>
          <v-col class="t-col" cols="2">
            <v-tooltip top v-if="!item.protocol">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 mt-2"
                  fab
                  dark
                  x-small
                  color="#3dbdad"
                  v-bind="attrs"
                  v-on="on"
                  @click="openGenerateProtocolDialog(item)"
                >
                  <v-icon> fas fa-file-invoice </v-icon>
                </v-btn>
              </template>
              <span>Генерирай протокол</span>
            </v-tooltip>
            <template v-if="item.protocol">
              <DownloadPdfButton
                :id="item.protocol.id"
                file-type="sample-register"
                text="Свали Протокол"
                list-action="sampleRegisterList"
              />
              <v-tooltip
                top
                v-if="user.role === 'Admin' || user.role === 'Quality Manager'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2 mt-2"
                    fab
                    dark
                    x-small
                    color="#3dbdad"
                    v-bind="attrs"
                    v-on="on"
                    @click="openGenerateProtocolDialog(item)"
                  >
                    <v-icon> fas fa-edit </v-icon>
                  </v-btn>
                </template>
                <span>Редакция</span>
              </v-tooltip>
            </template>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            {{ totalItems }} {{ $t("A.RESULTS") }}
          </v-col>
        </v-row>
      </div>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingSampleRegister"
      ></v-progress-linear>

      <Paginator
        :totalPages="totalPages"
        :page="headers.page"
        :maxVisibleButtons="tableOptions.maxVisibleButtons"
        @change-page="changePage"
      />
    </v-container>
    <v-dialog v-model="generateProtocolDialog.display" width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Генериране на протокол
        </v-card-title>

        <v-card-text class="d-flex flex-wrap">
          <div
            v-if="
              typeof error_messages === 'object' &&
                Object.keys(error_messages).length > 0
            "
            class="alert alert-danger"
          >
            <ul>
              <template v-for="(error, i) in error_messages">
                <span v-bind:key="i">
                  <li v-for="(message, ii) in error" v-bind:key="ii">
                    {{ message }}
                  </li>
                </span>
              </template>
            </ul>
          </div>
          <v-col cols="6">
            <div class="form-group">
              <label for="">
                <span> Продукт:</span>
              </label>
              <div class="field-wrap">
                <vu-select
                  append-to-body
                  :options="products"
                  label="name"
                  v-model="generateProtocolDialog.productId"
                  :value="generateProtocolDialog.productId"
                  :reduce="products => products.id"
                ></vu-select>
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="form-group">
              <label for="">
                <span> Фармацевтична форма:</span>
              </label>
              <div class="field-wrap">
                <vu-select
                  append-to-body
                  :options="formTypes"
                  label="name"
                  v-model="generateProtocolDialog.formTypeId"
                  :value="generateProtocolDialog.formTypeId"
                  :reduce="products => products.id"
                ></vu-select>
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="form-group">
              <label for="">
                <span> Партиден №:</span>
              </label>
              <div class="field-wrap">
                <input
                  v-model="generateProtocolDialog.batch"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="form-group">
              <label for="">
                <span> Срок на годност:</span>
              </label>
              <div class="field-wrap">
                <datetime
                  zone="local"
                  value-zone="local"
                  type="date"
                  v-model="generateProtocolDialog.expirationDate"
                  input-class="form-control"
                ></datetime>
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="form-group">
              <label for="">
                <span> Притежател на разрешение за употреба:</span>
              </label>
              <div class="field-wrap">
                <input
                  v-model="generateProtocolDialog.authorizationHolderUsage"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="form-group">
              <label for="">
                <span> Притежател на разрешение за паралелен внос:</span>
              </label>
              <div class="field-wrap">
                <vu-select
                  :options="clients"
                  label="name"
                  v-model="generateProtocolDialog.authorizationHolderImport"
                  :value="generateProtocolDialog.authorizationHolderImport"
                  :reduce="client => client.id"
                ></vu-select>
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="form-group">
              <label for="">
                <span> Големина на партидата:</span>
              </label>
              <div class="field-wrap">
                <input
                  v-model="generateProtocolDialog.quantity"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="form-group">
              <label for="">
                <span> Възложител за преопаковане:</span>
              </label>
              <div class="field-wrap">
                <vu-select
                  :options="clients"
                  label="name"
                  v-model="generateProtocolDialog.clientRepackaging"
                  :value="generateProtocolDialog.clientRepackaging"
                  :reduce="client => client.id"
                ></vu-select>
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="form-group">
              <label for="">
                <span>
                  Дата на изтичане срока за съхранение на музейната
                  мостра:</span
                >
              </label>
              <div class="field-wrap">
                <datetime
                  zone="local"
                  value-zone="local"
                  type="date"
                  v-model="generateProtocolDialog.keepingEndDate"
                  input-class="form-control"
                ></datetime>
              </div>
            </div>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="saveProtocol()">
            {{ generateProtocolDialog.id > 0 ? "Редактирай" : "Генерирай" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

import ListMixin from "@/common/mixins/List";
import Paginator from "@/components/Paginator";
import DownloadPdfButton from "@/components/ActionButtons/DownloadPdfButton";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { GET_ALL_CLIENTS } from "@/store/clients.module";
import { GET_ALL_PRODUCTS } from "@/store/products.module";
import { GET_DATA } from "@/store/formselect.module";

import {
  GET_ALL_SAMPLE_REGISTER,
  GENERATE_PROTOCOL,
  UPDATE_PROTOCOL,
  DOWNLOAD_SAMPLE_REGISTER
} from "@/store/sampleRegister.module";

import { mapGetters } from "vuex";
import { API_URL } from "@/common/config";
import moment from "moment";

export default {
  name: "SampleRegisterList",
  components: { Paginator, DownloadPdfButton },
  mixins: [ListMixin],
  data() {
    return {
      startLoading: true,
      error_messages: {},
      search: "",
      formTypes: [],
      clients: [],
      products: [],
      action: "sampleRegister",
      tableOptions: {
        showSearch: false,
        draggableCols: true,
        selectable: true,
        rowsPerPage: [2, 10, 20, 50],
        maxVisibleButtons: 5
      },
      headers: {
        columns: [
          {
            name: "id",
            cols: "1",
            hide: true
          },
          {
            name: "routeMap.product.name",
            search: ""
          },
          {
            name: "routeMap.product.archived_from_id",
            search: "",
            hide: true
          },
          {
            name: "routeMap.product.id",
            search: "",
            hide: true
          },
          {
            name: "acceptanceActDetail.rawProduct.formTypeId",
            search: "",
            hide: true
          },
          {
            name: "acceptanceActDetail.batch",
            search: ""
          },
          {
            name: "acceptanceActDetail.acceptanceAct.client.name",
            search: ""
          },
          {
            name: "acceptanceActDetail.acceptanceAct.client.archived_from_id",
            search: "",
            hide: true
          },
          {
            name: "routeMap.finishDate",
            search: ""
          },
          {
            name: "acceptanceActDetail.expirationDate",
            search: ""
          },
          {
            name: "keepingEndDate",
            search: "",
            hide: true
          },
          {
            name: "acceptanceActDetail.acceptanceAct.client.id",
            search: "",
            hide: true
          },
          {
            name: "routeMap.createdQuantity",
            search: "",
            hide: true
          },
          {
            name: "typeableId",
            search: "",
            hide: true
          },
          {
            name: "routeMapId",
            search: "",
            hide: true
          },
          {
            name: "protocol",
            search: "",
            hide: true
          }
        ],
        start_date: null,
        end_date: null,
        client: "",
        search: "",
        rows: 10,
        page: 1,
        order: "id",
        orderType: "asc"
      },
      items: [],
      generateProtocolDialog: {
        id: null,
        routeMapId: null,
        productId: null,
        clientId: null,
        formTypeId: null,
        batch: null,
        expirationDate: null,
        authorizationHolderUsage: null,
        authorizationHolderImport: null,
        quantity: null,
        clientRepackaging: null,
        keepingEndDate: null,
        display: false
      },
      totalItems: 0,
      totalPages: 0
    };
  },
  computed: {
    ...mapGetters(["isLoadingSampleRegister"]),
    orderIcon: function() {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    },
    visiblePages() {
      const range = [];
      let vm = this;

      let start, end;

      if (vm.headers.page < Math.ceil(vm.tableOptions.maxVisibleButtons / 2)) {
        start = 1;

        if (vm.tableOptions.maxVisibleButtons > vm.totalPages) {
          end = vm.totalPages;
        } else {
          end = vm.tableOptions.maxVisibleButtons;
        }
      } else if (
        vm.headers.page + Math.ceil(vm.tableOptions.maxVisibleButtons / 2) >
        vm.totalPages
      ) {
        start = vm.totalPages - vm.tableOptions.maxVisibleButtons + 1;
        end = vm.totalPages;
      } else {
        start =
          vm.headers.page - Math.floor(vm.tableOptions.maxVisibleButtons / 2);
        end =
          vm.headers.page + Math.floor(vm.tableOptions.maxVisibleButtons / 2);
      }

      for (let i = start; i <= end; i += 1) {
        range.push({
          name: i,
          isDisabled: i === vm.headers.page
        });
      }

      return range;
    },
    user: function() {
      return JSON.parse(window.localStorage.getItem("userData"));
    }
  },
  watch: {
    "headers.start_date": _.debounce(function() {
      let vm = this;

      vm.headers.page = 1;

      if (!vm.startLoading) {
        vm.fetchData();
      }
    }, 250),
    "headers.end_date": _.debounce(function() {
      let vm = this;

      vm.headers.page = 1;

      if (!vm.startLoading) {
        vm.fetchData();
      }
    }, 250),
    "headers.search": _.debounce(function() {
      let vm = this;

      vm.fetchData();
    }, 250),
    "headers.rows": _.debounce(function(new_value) {
      let vm = this;
      new_value = parseInt(new_value);
      window.localStorage.setItem(vm.action + "_rows", new_value);

      vm.headers.page = 1;
      vm.fetchData();
    }, 250),
    "headers.page": _.debounce(function() {
      let vm = this;
      vm.fetchData();
    }, 250)
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Стока в наличност" }]);

    vm.getTypes();

    Promise.all([vm.getClients(), vm.getProducts()]).then(() => {
      vm.fetchData();
    });
  },
  methods: {
    getProducts: function() {
      let vm = this;
      this.error_messages = [];
      return this.$store
        .dispatch(GET_ALL_PRODUCTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.products = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
          }
        });
    },
    getTypes: function() {
      let vm = this;

      let payload = {
        formTypes: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.error_messages = [];
      this.$store
        .dispatch(GET_DATA, payload)
        .then(data => {
          vm.$nextTick(function() {
            vm.formTypes = data.data.formTypes;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getClients: function() {
      let vm = this;

      this.error_messages = [];
      return vm.$store
        .dispatch(GET_ALL_CLIENTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.clients = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getOptions: function() {
      let vm = this;

      vm.headers.order =
        window.localStorage.getItem(vm.action + "_order") || "id";
      vm.headers.orderType =
        window.localStorage.getItem(vm.action + "_orderType") || "asc";
      vm.headers.rows = parseInt(
        window.localStorage.getItem(vm.action + "_rows") || 10
      );
    },
    changeOrder: _.debounce(function(key) {
      let vm = this;

      if (vm.headers.order == key) {
        let oType = vm.headers.orderType == "asc" ? "desc" : "asc";

        vm.headers.orderType = oType;
      } else {
        vm.headers.order = key;
      }

      vm.headers.page = 1;
      window.localStorage.setItem(vm.action + "_order", vm.headers.order);
      window.localStorage.setItem(
        vm.action + "_orderType",
        vm.headers.orderType
      );

      vm.fetchData();
    }, 250),
    openGenerateProtocolDialog: function(item) {
      this.generateProtocolDialog.id = item.protocol?.id;
      this.generateProtocolDialog.authorizationHolderUsage =
        item.protocol?.authorizationHolderUsage;
      this.generateProtocolDialog.productId = item["routeMap.product.id"];
      this.generateProtocolDialog.formTypeId =
        item["acceptanceActDetail.rawProduct.formTypeId"];
      this.generateProtocolDialog.batch = item["acceptanceActDetail.batch"];
      this.generateProtocolDialog.authorizationHolderImport =
        item["acceptanceActDetail.acceptanceAct.client.id"];
      this.generateProtocolDialog.expirationDate =
        item["acceptanceActDetail.expirationDate"];
      this.generateProtocolDialog.clientRepackaging =
        item["acceptanceActDetail.acceptanceAct.client.id"];
      this.generateProtocolDialog.quantity = item["routeMap.createdQuantity"];
      this.generateProtocolDialog.keepingEndDate = item["keepingEndDate"];
      this.generateProtocolDialog.routeMapId = item["routeMapId"];
      this.generateProtocolDialog.display = true;
    },
    getSearchPayload: function() {
      return {
        search: this.headers.search,
        client: this.headers.client,
        start_date: this.headers.start_date,
        end_date: this.headers.end_date
      };
    },
    fetchData: _.debounce(function() {
      let vm = this;

      vm.$store
        .dispatch(GET_ALL_SAMPLE_REGISTER, { payload: vm.headers })
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data.data;
            vm.totalItems = data.data.recordsTotal;
            vm.totalPages = data.data.totalPages;
            vm.startLoading = false;

            let tempProducts = {};
            let tempClients = {};
            vm.items.forEach(item => {
              if (item["routeMap.product.archivedFromId"]) {
                tempProducts[item["routeMap.product.id"]] = {
                  id: item["routeMap.product.id"],
                  name: item["routeMap.product.name"] + " (Архивиран)"
                };
              }

              if (
                item["acceptanceActDetail.acceptanceAct.client.archivedFromId"]
              ) {
                tempClients[
                  item["acceptanceActDetail.acceptanceAct.client.id"]
                ] = {
                  id: item["acceptanceActDetail.acceptanceAct.client.id"],
                  name:
                    item["acceptanceActDetail.acceptanceAct.client.name"] +
                    " (Архивиран)"
                };
              }
            });

            Object.values(tempProducts).forEach(product => {
              vm.products.push(product);
            });

            Object.values(tempClients).forEach(client => {
              vm.clients.push(client);
            });

            vm.products = _.uniqBy(vm.products, "id");
            vm.clients = _.uniqBy(vm.clients, "id");
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "dashboard" });
          }
        });
    }, 500),
    saveProtocol: _.debounce(function() {
      let vm = this;

      vm.error_messages = [];

      let payload = {
        payload: {
          ...vm.generateProtocolDialog,
          expirationDate: moment(
            vm.generateProtocolDialog.expirationDate
          ).format("YYYY-MM-DD"),
          keepingEndDate: moment(
            vm.generateProtocolDialog.keepingEndDate
          ).format("YYYY-MM-DD")
        }
      };

      let action = vm.generateProtocolDialog.id
        ? UPDATE_PROTOCOL
        : GENERATE_PROTOCOL;

      vm.$store
        .dispatch(action, payload)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.fetchData();
          vm.generateProtocolDialog.display = false;
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    }),
    downloadData: _.debounce(function() {
      let vm = this;

      vm.$store
        .dispatch(DOWNLOAD_SAMPLE_REGISTER)
        .then(data => {
          let payload = vm.getSearchPayload();

          window.open(
            API_URL +
              `sample/export/${data.data.code}?search=${payload.search}&start_date=${payload.start_date}&end_date=${payload.end_date}`
          );
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "dashboard" });
          }
        });
    }, 500)
  }
};
</script>
